import {
  get_all_orders,
  get_all_orders_details,
  get_all_orders_pickups,
  get_organizations,
  get_depots_all,
  create_new_order,
  create_new_org,
  create_order_gb,
  get_single_order_details_by_docNo,
  get_all_orders_details_with_eoo_release,
  sent_serve_bos_email,
} from "@/helpers/api/indexv2.js";
import { search } from "@/utils/panelAction.js";

import {
  cancel_order,
  get_orders_term,
  get_orders_types,
  create_payment,
  get_order_activities,
  quote_2_open_order,
  save_quote,
  close_order,
  get_payment_details,
  get_release_details,
  assign_release,
  triggerReleaseHeader,
  apply_credit,
  create_draft_order,
  get_draft_order,
  delete_draft_order,
  set_invoice_bill,
  set_originalEntityID,
  get_order_type,
} from "@/helpers/api/Orders/index.js";

import {
  getCustomFieldByOrder,
  getListOfCustomFieldsForOrganization,
  update_custom_field_value,
  create_custom_field_by_order,
} from "@/helpers/api/CustomFields/index.js";

import moduleDetails from "@/store/modules/orders/Details";
import { gateType } from "@/helpers/gate_buy.js";

export default {
  namespaced: true,
  state: {
    tabSelected: {},
    allOrders: [],
    allOrdersDetails: [],
    allDepots: [],
    searchInput: "",
    customers: [],
    customerData: { transStepId: 2 },
    ordersData: [],
    ordersTerm: [],
    ordersType: [],
    orderActivities: [],
    orderFiles: [],
    historySwitch: false,

    credits: [],
    creditsTotal: null,
    creditsSelected: [],
    draft: null,
    filters: [],
    eoo: null,
    openLength: 0,
    completeLength: 0,
    historyLength: 0,
    docDetailsNosOrder: {},
    entityBill: null,
    newContainers: [],
  },
  mutations: {
    setEntityBill(state, newState) {
      state.entityBill = newState;
    },
    setDocDetailsNosOrder(state, newState) {
      state.docDetailsNosOrder = newState;
    },
    setHistoryLength(state, newState) {
      state.historyLength = newState;
    },
    setCompleteLength(state, newState) {
      state.completeLength = newState;
    },
    setOpenLength(state, newState) {
      state.openLength = newState;
    },
    setEOO(state, newState) {
      state.eoo = newState;
    },
    setFilters(state, newState) {
      state.filters = newState;
    },
    setHistorySwitch(state, newState) {
      state.historySwitch = newState;
    },
    setTabSelected(state, newState) {
      state.tabSelected = newState;
    },
    setTabDetails(state, newState) {
      state.tabSelected.details = newState;
    },
    setAllOrders(state, newState) {
      state.allOrders = newState;
    },
    setAllOrdersDetails(state, newState) {
      state.allOrdersDetails = newState;
    },
    setDetails(state, newState) {
      state.tabSelected = { details: newState };
    },
    setSearchInput(state, newState) {
      state.searchInput = newState;
    },

    setCustomer(state, newState) {
      state.customers = newState;
    },
    setOrdersData(state, newState) {
      state.ordersData = newState;
    },
    setCustomerData(state, newState) {
      state.customerData = newState;
    },
    setOrdersTerm(state, newState) {
      state.ordersTerm = newState;
    },
    setOrdersType(state, newState) {
      state.ordersType = newState;
    },
    setAllDepots(state, newState) {
      state.allDepots = newState;
    },
    setOrderActivities(state, newState) {
      state.orderActivities = newState;
    },
    setOrderFiles(state, newState) {
      state.orderFiles = newState;
    },
    setIsTBD(state, newState) {
      state.customerData.isTBD = newState;
    },
    setCredits(state, newState) {
      state.credits = newState;
    },
    setCreditsTotal(state, newState) {
      state.creditsTotal = newState;
    },
    setCreditsSelected(state, newState) {
      state.creditsSelected = newState;
    },
    setDraft(state, newState) {
      state.draft = newState;
    },
  },
  getters: {
    ordersData(state) {
      return state.ordersData;
    },

    tabDetails(state) {
      return state.tabSelected.details;
    },
    orderType(state) {
      return state.tabSelected.details?.order_type;
    },
    docNo(state) {
      return state.tabSelected.details && state.tabSelected.details.docNo
        ? state.tabSelected.details.docNo
        : false;
    },
    // totalAmount(state) {
    //   let total = 0;
    //   state.ordersData.map(({ qty, buyerPrice }) => {
    //     total += Number(qty) * Number(buyerPrice);
    //   });

    //   return total;
    // },
    // creditsSelectedBalance(state) {
    //   let total = 0;
    //   state.creditsSelected.map(({ balance }) => {
    //     total += Number(balance);
    //   });

    //   return total;
    // },
  },
  actions: {
    setTabLengths({ commit }, { open, complete, history }) {
      commit("setOpenLength", open);
      commit("setCompleteLength", complete);
      commit("setHistoryLength", history);
    },
    async getAllOrderWithDetails({ commit }) {
      try {
        let data = await Promise.all([
          get_all_orders(),
          get_all_orders_details(),
          get_all_orders_details_with_eoo_release(),
        ]);

        const eooGrouped = data[2].reduce((acc, item) => {
          acc[item.docNo] = acc[item.docNo]
            ? [...acc[item.docNo], item]
            : [item];
          return acc;
        }, {});

        const docDetails = data[1].reduce((acc, item) => {
          acc[item.docNo] = acc[item.docNo]
            ? [...acc[item.docNo], item]
            : [item];
          return acc;
        }, {});

        commit("setAllOrders", data[0]);
        commit("setAllOrdersDetails", data[1]);
        commit("setEOO", eooGrouped);
        commit("setDocDetailsNosOrder", docDetails);

        commit(
          "setSnackbar",
          {
            text: "All Orders fetched with details!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        return true;
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error && error.data ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async getsAllOrders({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let data = await get_all_orders();

        console.log("getAllOrdr data", data);

        commit("setAllOrders", data);
        commit(
          "setSnackbar",
          {
            text: "All Orders fetched!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getAllOrdersDetail({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let data = await get_all_orders_details();

        commit("setAllOrdersDetails", data);

        /////////
        console.log("setAllOrdersDetails data", data);

        commit(
          "setSnackbar",
          {
            text: `setAllOrdersDetails data ${data}`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        ////////

        commit(
          "setSnackbar",
          {
            text: "All Orders Details fetched!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getOrderActivities({ commit }, { order }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let data = await get_order_activities(order);
        commit("setOrderActivities", data);
        commit(
          "setSnackbar",
          {
            text: "All Order's activities  fetched!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log("error activities", error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    getOrderDetail({ state }, { docNo }) {
      return state.allOrdersDetails.filter((details) => details.docNo == docNo);
    },

    getOrderDetailByNerNo({ state }, { nereus_no, docNo }) {
      return state.allOrdersDetails.filter(
        (details) => details.nereus_no == nereus_no
      );
    },

    getOrder({ state }, { docNo }) {
      return state.allOrders.filter((order) => order.docNo == docNo);
    },

    getOrderNerNo({ state }, { nereus_no, docNo }) {
      return state.allOrders.filter((order) => order.nereus_no == nereus_no);
    },
    async cancelOrder({ commit }, payload) {
      commit("setSpinnerShow", true, { root: true });
      try {
        await cancel_order(payload);

        commit(
          "setSnackbar",
          {
            text: "Order Canceled",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async closeOrder({ commit }, payload) {
      console.log(payload);
      commit("setSpinnerShow", true, { root: true });
      try {
        await close_order(payload);

        commit(
          "setSnackbar",
          {
            text: "Order Closed",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async quote2OpenOrder({ commit }, payload) {
      commit("setSpinnerShow", true, { root: true });
      try {
        const resp = await quote_2_open_order(payload);

        commit(
          "setSnackbar",
          {
            text: "Open Order generated",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.lorg(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${error ? error : "Server problem"}`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async getCustomer({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await get_organizations();

        commit("setCustomer", data);
        commit(
          "setSnackbar",
          {
            text: "Customers fetched!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getDepotsAll({ commit }) {
      try {
        let { data } = await get_depots_all();

        commit("setAllDepots", data);
      } catch (error) {
        console.log(error);
      }
      return;
    },
    async getOrdersTerm({ commit }) {
      try {
        let data = await get_orders_term();

        commit("setOrdersTerm", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getOrdersType({ commit }) {
      try {
        let data = await get_orders_types();

        commit("setOrdersType", data);
      } catch (error) {
        console.log(error);
      }
    },
    orderTypeId({ state }, { typeName }) {
      return state.ordersType.find((type) => type.orderTypeId === typeName).id;
    },
    creditTermId({ state }, { termName }) {
      return state.ordersTerm.find((type) => type.creditTermId === termName).id;
    },
    // async createNewOrder({ commit }, { data, isGateBuy }) {
    //   commit("setSpinnerShow", true, { root: true });
    //   console.log(isGateBuy);
    //   let response;
    //   try {
    //     response = isGateBuy
    //       ? await create_new_order({
    //           data,
    //         })
    //       : await create_order_gb(data);

    //     commit(
    //       "setSnackbar",
    //       {
    //         text: response.message,
    //         status: "success",
    //         show: "showNav",
    //       },
    //       { root: true }
    //     );
    //   } catch (error) {
    //     console.log("error");
    //     commit(
    //       "setSnackbar",
    //       {
    //         text: `Something went wrong. ${
    //           error ? error.data.message : "Server problem"
    //         }`,
    //         status: "danger",
    //         show: "showNav",
    //       },
    //       { root: true }
    //     );
    //     console.log(error);
    //   }
    //   commit("setSpinnerShow", false, { root: true });
    //   return response;
    // },
    async saveCustomer({ commit }, { data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await create_new_org(data);

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        let customer = await get_organizations();

        commit("setCustomer", customer.data);
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async createPayment({ commit, getters, dispatch }, { data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        let paymentResponse = await create_payment({
          ...data,
        });

        response = await get_payment_details(getters.tabDetails.id);

        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: getters.tabDetails.custEmails,
        //     subject: `CMA-CGM:: Bill of Sale No: ${getters.tabDetails.docNo}`,
        //     BOSNumber: getters.tabDetails.docNo,
        //   },
        //   { root: true }
        // );
        commit("moduleOrders/moduleDetails/setPaymentDetails", response, {
          root: true,
        });

        commit(
          "setSnackbar",
          {
            text: paymentResponse.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },

    async applyCredit({ commit, getters, dispatch }, { data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        let response = await apply_credit({
          ...data,
        });

        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: getters.tabDetails.custEmails,
        //     subject: `CMA-CGM:: Bill of Sale No: ${getters.tabDetails.docNo}`,
        //     BOSNumber: getters.tabDetails.docNo,
        //   },
        //   { root: true }
        // );
        if (getters.tabDetails.id !== undefined) {
          response = await get_payment_details(getters.tabDetails.id);
          commit("moduleOrders/moduleDetails/setPaymentDetails", response, {
            root: true,
          });
        }

        commit(
          "setSnackbar",
          {
            text: "Credit applied",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },

    async save_quote({ commit, dispatch }, data) {
      const { invoice_no } = data;
      console.log(data);
      // if (data.dueDate)
      //   data.dueDate = parseDateFromFormat(
      //     data.serverDate_dueDate,
      //     "yyyy-LL-dd HH-mm-ss",
      //     "yyyy-LL-dd",
      //   );
      commit("setSpinnerShow", true, { root: true });
      try {
        await save_quote(data);
        let response = await get_all_orders();

        commit("setAllOrders", response);

        let order = await dispatch("getOrder", { docNo: data.invoice_no });
        const res = await get_order_type(invoice_no);
        console.log(res);
        commit("setDetails", order[0]);
        commit(
          "moduleOrders/moduleDetails/setOrderTypeDetails",
          res.data.data,
          {
            root: true,
          }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async get_custom_field_by_org({ commit }, id) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await getListOfCustomFieldsForOrganization(id);
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });

      return res.data.data;
    },
    async get_custom_field_by_order({ commit }, docNo) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await getCustomFieldByOrder(docNo);
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async updateCustomFieldValue({ commit }, customFields) {
      commit("setSpinnerShow", true, { root: true });
      let res;

      try {
        res = await Promise.allSettled(
          customFields.map(({ id, value }) => {
            update_custom_field_value({ id, FieldValue: `${value}` });
          })
        );
        commit(
          "setSnackbar",
          {
            text: "Saved",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async createCustomFieldByOrder({ commit }, { customFields, docNo }) {
      commit("setSpinnerShow", true, { root: true });
      let res;
      try {
        res = await Promise.allSettled(
          customFields.map((values) => {
            create_custom_field_by_order({
              fieldName: values.FieldName,
              fieldType: values.fieldType,
              fieldValue: `${values.value}`,
              docNo,
            });
          })
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return res;
    },
    async createOrder(
      { commit, dispatch, state },
      { order, customFields, customer, isGateBuy }
    ) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      let nc;

      try {
        if (customer) {
          nc = await create_new_org(customer);

          order.customerEntityId = nc.customer_organization_id;
          setTimeout(async () => {
            await get_organizations();
          }, 1000);
        }

        response = isGateBuy
          ? await create_order_gb(order)
          : await create_new_order(order);
        if (state.entityBill) {
          await set_invoice_bill({
            orgID: state.entityBill,
            docNo: response.data.docNo,
          });
        }
        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: order.primary_email,
        //     subject: `CMA-CGM:: Bill of Sale No: ${response.data.docNo}`,
        //     BOSNumber: response.data.docNo,
        //   },
        //   { root: true }
        // );
        if (customFields && customFields.length)
          await Promise.allSettled(
            customFields.map((values) => {
              create_custom_field_by_order({
                fieldName: values.FieldName,
                fieldType: values.fieldType,
                fieldValue: `${values.value}`,
                docNo: response.data.docNo,
              });
            })
          );

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error.data);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error?.data?.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });

      return isGateBuy ? response.docNo : response.data.docNo;
    },

    async createRelease({ commit, getters }, { data, orgID }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        let response = await assign_release({
          ...data,
        });

        if (orgID)
          await set_originalEntityID({
            orgID,
            docNo: data.docNo,
          });
        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error.response);

        commit(
          "setSnackbar",
          {
            text: error.response.data.message,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async triggerReleaseHeader(
      { commit },
      { customerCode, docNo, depotIDSelected }
    ) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await triggerReleaseHeader({
          customerCode,
          docNo,
          depotIDSelected,
        });
        if (response.status === 200) {
          commit(
            "setSnackbar",
            {
              text: "Generated release order code",
              status: "success",
              show: "showNav",
            },
            { root: true }
          );
        } else {
          commit(
            "setSnackbar",
            {
              text:
                "Error. Please submit a Customer code to generate a Customer Code.",
              status: "danger",
              show: "showNav",
            },
            { root: true }
          );
        }
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async createDraftOrder({ commit, state }, { data }) {
      commit("setSpinnerShow", true, { root: true });

      if (state.draft) {
        await delete_draft_order();
      }
      try {
        await create_draft_order({
          data,
        });
        commit("setDraft", data);

        commit(
          "setSnackbar",
          {
            text: "Draft saved",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: "Error. Something went wrong",
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getDraft({ commit }) {
      try {
        let { data } = await get_draft_order();

        commit("setDraft", data.data);
      } catch (error) {}
    },
    async deleteDraftOrder({ commit }) {
      try {
        await delete_draft_order();

        commit(
          "setSnackbar",
          {
            text: "Draft deleted",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        commit("setDraft", null);
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: "Error. Something went wrong",
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
    },
    isGateBuy({ state }, orderType) {
      // console.log(orderType);
      const typeData = state.ordersType.filter((x) => x.id === orderType);

      return gateType.order_type_desc === typeData[0]?.code;
    },
    isGateBuySoft({ state }, orderType) {
      const typeData = state.ordersType.filter((x) => x.id === orderType);

      return typeData[0]?.typeDesc === "Gate Buy Soft";
    },
  },
  modules: {
    moduleDetails,
  },
};
